import React, { useState } from "react"
import { Button, Col, Form } from "react-bootstrap"
import axios from "axios"

const TailoringForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [phone, setPhone] = useState("")
  const [submit, setSubmit] = useState(true)
  const [notification, setNotification] = useState("")
  const sendEmailHandler = e => {
    e.preventDefault()
    axios.post("https://ahe5do8969.execute-api.us-west-2.amazonaws.com/dev/email/send", {
      name,
      email,
      message,
      phone
    }).then((res) => {
      if (res.status === 200) {
        setNotification("Yêu cầu đã được gửi thành công. Chúng tôi sẽ liên lạc với bạn trong thời gian sớm nhất.")
      } else {
        setNotification("Yêu cầu gửi thất bại. Vui lòng thử lại sau.")
        setSubmit(false)
      }
    });
  }
  return (
    <>
      <h2 className="title-lg text-center">May đo theo yêu cầu</h2>
      <div>
        {notification !== "" && (<div className={`text-center mb-1 rounded p-1 text-light+ ${submit ? "bg-success" : "bg-danger"}`}>{notification}</div>)}
        <Form className={"contact-form"} onSubmit={(e) => sendEmailHandler(e)}>
          <Form.Row>
            <Col lg={6}>
              <Form.Group controlId="formGridName">
                <Form.Control required onChange={e => setName(e.target.value)} type="text" placeholder="Họ và tên" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formGridPhone">
                <Form.Control required onChange={e => setPhone(e.target.value)} type="text"
                              placeholder="Số điện thoại" />
              </Form.Group>
            </Col>

          </Form.Row>

          <Form.Group controlId="formGridEmail">
            <Form.Control required onChange={e => setEmail(e.target.value)} type="email" placeholder="Địa chỉ email" />
          </Form.Group>

          <Form.Group controlId="formGridContent">
            <Form.Control required onChange={e => setMessage(e.target.value)} as="textarea"
                          placeholder="Yêu cầu thiết kế" rows={3} />
          </Form.Group>

          <Button variant="primary" type="submit" className={"btn-outline-darker btn-more"}>
            Gửi
          </Button>
        </Form>

      </div>
    </>
  )
}

export default TailoringForm